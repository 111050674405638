




/* eslint-disable no-invalid-this */
import { addMinutes, parseISO } from "date-fns";
import { Vue, Component } from "vue-property-decorator";
// eslint-disable-next-line no-unused-vars
import FullCalendar, { CalendarOptions, EventInput } from "@fullcalendar/vue";
import timeGridPlugin from "@fullcalendar/timegrid";
import ptBrLocale from "@fullcalendar/core/locales/pt-br";

import appointmentModel from "@/models/appointment.model";

@Component({
  components: {
    FullCalendar,
  },
})
export default class CalendarPsychologist extends Vue {
  loadingIndicator;

  calendarOptions: CalendarOptions = {
    plugins: [ timeGridPlugin ],
    locale: ptBrLocale,
    initialView: "timeGridWeek",
    height: "100%",
    expandRows: true, // if screen is taller than calendar
    scrollTime: "08:00:00", // initial time to scroll to
    allDaySlot: false,
    dayHeaderContent: (headerContent) => { // format days of the week
      const headerParts = headerContent.text.match(/^(...)\. (\d\d)\/\d\d$/); // extract day of the week and day
      if (headerParts) {
        return { html: `<div class="calendar-header-day">${headerParts[2]}</div><div class="calendar-header-dayweek">${headerParts[1].toUpperCase()}</div>` };
      }
    },
    slotLabelContent: (labelContent) => { // format hours of the day
      return `${labelContent.text}h`;
    },
    events: eventsInfo => this.getScheduledEvents(eventsInfo.start, eventsInfo.end),
    loading: (isLoading) => {
      if (isLoading) {
        this.loadingIndicator = this.$vs.loading({ color: '#FABA01' });
      } else if (this.loadingIndicator) {
        this.loadingIndicator.close();
      }
    },
    eventClick: (event) => this.openEvent(event),
  };

  async getScheduledEvents(fromDate: Date, toDate: Date): Promise<EventInput[]> {
    const appointments = await appointmentModel.getSchedule(fromDate, toDate);
    return appointments
      .filter(appointment => appointment.kind !== 'DOUBLE_EXTENSION')
      .map((appointment: any) => ({
        id: appointment.id,
        title: appointment.patientUser?.name,
        start: appointment.startDateTime.replace('Z',''),
        end: addMinutes(parseISO(appointment.startDateTime.replace('Z','')), this.getKindDuration(appointment.kind)),
      }));
  }

  getKindDuration(kind: string) {
    switch (kind) {
      case "SINGLE":
        return 30; //2806 alterar para 45 min
        //return 45;
      case "DOUBLE":
        return 50;
    }
    return 0;
  }

  async openEvent(event: any) {
    this.$router.push(`/dash/consulta/${event.event.id}`)
  }
}
