




/* eslint-disable no-invalid-this */
import { Vue, Component } from "vue-property-decorator";
// eslint-disable-next-line no-unused-vars
import FullCalendar, { CalendarOptions, EventInput } from "@fullcalendar/vue";
import dayGridPlugin from "@fullcalendar/daygrid";
import ptBrLocale from "@fullcalendar/core/locales/pt-br";

import appointmentModel from "@/models/appointment.model";

@Component({
  components: {
    FullCalendar,
  },
})
export default class CalendarPatient extends Vue {
  loadingIndicator;

  calendarOptions: CalendarOptions = {
    plugins: [ dayGridPlugin ],
    locale: ptBrLocale,
    initialView: "dayGridMonth",
    height: "100%",
    eventTimeFormat: {
      hour: "2-digit",
      minute: "2-digit",
      meridiem: false,
    },
    dayHeaderContent: headerContent => headerContent.text.toUpperCase().slice(0, 3),
    eventContent: (eventContent) => ({
        html: `
          <div class="md:hidden overflow-hidden overflow-ellipsis">
            <img class="w-10 h-10 rounded-full mb-2" src="${eventContent.event.extendedProps?.pictureUrl || '/assets/images/profile_placeholder.png'}">
            ${eventContent.timeText}<br>${eventContent.event.title}
          </div>
          <div class="calendar-appointment hidden md:flex">
            <div class="calendar-appointment__info">
              <img src="${eventContent.event.extendedProps?.pictureUrl || '/assets/images/profile_placeholder.png'}">
              <span>${eventContent.timeText}<br>${eventContent.event.title}</span>
            </div>
            <div class="calendar-appointment__action">Ver detalhes</div>
          </div>`
    }),
    events: eventsInfo => this.getScheduledEvents(eventsInfo.start, eventsInfo.end),
    loading: (isLoading) => {
      if (isLoading) {
        this.loadingIndicator = this.$vs.loading({ color: '#FABA01' });
      } else if (this.loadingIndicator) {
        this.loadingIndicator.close();
      }
    },
    eventClick: (event) => this.openEvent(event),
  };

  async getScheduledEvents(fromDate: Date, toDate: Date): Promise<EventInput[]> {
    const appointments = await appointmentModel.getSchedule(fromDate, toDate);
    return appointments
      .filter(appointment => appointment.kind !== 'DOUBLE_EXTENSION')
      .map((appointment: any): EventInput => ({
        id: appointment.id,
        title: appointment.psychologistUser?.name,
        start: appointment.startDateTime.replace('Z',''),
        pictureUrl: appointment.psychologistUser?.pictureUrl,
      }));
  }

  async openEvent(event: any) {
    this.$router.push(`/dash/consulta/${event.event.id}`)
  }
}
