







import { Vue, Component } from "vue-property-decorator";
import CalendarPsychologist from "./schedule-components/CalendarPsychologist.vue";
import CalendarPatient from "./schedule-components/CalendarPatient.vue";

@Component({
  components: {
    CalendarPsychologist,
    CalendarPatient,
  },
})
export default class Schedule extends Vue {
  get userRole() {
    return this.$store.state.activeUser?.role;
  }
}
